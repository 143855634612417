import React from "react";
import { graphql } from "gatsby";
import Cards from "../../components/Cards";

import Layout from "../../layouts/Layout";
import SiteMetadata from "../../components/SiteMetadata";

import Featured from "../../components/Featured";

function FreePage({ data }) {
	return (
		<Layout>
			<SiteMetadata
				title='Free Prints'
				description='The Pixel Prince Free Wall Art'
			/>

			<main className='bg-white font-montserrat xl:mt-10 mb-6  lg:mb-16'>
				<header className='h-10 sm:h-12 flex items-center'></header>
				<div className='container mx-auto px-6 sm:px-12 flex flex-col-reverse sm:flex-row items-center'>
					<div className='sm:w-2/5 flex flex-col items-start mt-8 sm:mt-0'>
						<h2 className='text-4xl tracking-tight leading-10 font-extrabold text-red-400  sm:text-5xl sm:leading-none md:text-6xl'>
							Hooray!
						</h2>
						<span className='mt-8 text-3xl tracking-tight leading-10 font-extrabold text-gray-900  sm:text-4xl sm:leading-none md:text-5xl'>
							You've found our secret page.
						</span>
					</div>
					<div className=' w-full sm:w-3/5  ml-2'>
						<img
							className=' object-right sm:object-top md:object-right lg:object-bottom xl:object-left '
							//   src='https://res.cloudinary.com/babyhulk/image/upload/v1599243687/hero-image/video_game_poster_main.jpg'
							src='https://res.cloudinary.com/babyhulk/image/upload/v1599336988/hero-image/surprise-01.svg'
							alt='Happy People'
						/>
					</div>
				</div>
			</main>

			<div className='bg-orange-100 py-12  lg:py-16'>
				<div class='lg:text-center mb-12'>
					<p class='text-base leading-6 text-orange-500 font-semibold tracking-wide uppercase'>
						Free
					</p>
					<h3 class='mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10'>
						Free Printable Wall Art
					</h3>
					<p class='mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto'>
						Welcome! Maybe, you made a purchased an item from "The Pixel Prince"
						shop or you just stumbled on this page. Either way, it's time to
						show your home and your wallet some love with free wall art. More
						FREE prints will be added periodically.
					</p>
				</div>

				{data.portfolio && data.portfolio.nodes.length > 0 ? (
					<Cards items={data.portfolio.nodes} />
				) : (
					<div className='container'>No projects found.</div>
				)}
			</div>
			
			<Featured></Featured>

			{/*<Newsletter /> */}
		</Layout>
	);
}

export default FreePage;

export const query = graphql`
	query FreePrintsQuery {
		portfolio: allContentfulPortfolio(filter: {isFree: {eq: true}})  {
			nodes {
				...PortfolioCard
			}
		}
	}
`;
